<template>
  <div class="container pb-12">
    <h2 class="pt-10 font-bold">
      TEILNAHMEBEDINGUNGEN IQOS GEWINNSPIEL “UMFRAGE ACCESSOIRES CUSTOMIZATION”
    </h2>
    <h3 class="font-bold pb-4 pt-8">
      1. AKTION  “UMFRAGE ACCESSOIRES CUSTOMIZATION”
    </h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">1.1</span>
        <span>
          Die Aktion „Umfrage Accessoires Customization, im folgenden „Aktion“ genannt,
          wird veranstaltet von IQOS, einem Geschäftsbereich der Philip Morris GmbH, Am Haag
          14, 82166 Gräfelfing, im Folgenden „Veranstalter“ genannt.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">1.2</span>
        <span>
          Die Aktion findet im Zeitraum vom 11.07.2022 (00:00:00 Uhr) bis 16.08.2022
          (23:59:59) statt.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">1.3</span>
        <span>
          Die Teilnahme ist freiwillig, kostenlos und nicht an eine Kaufverpflichtung
          gekoppelt. Die Teilnehmer (im Folgenden gelten Personenbezeichnungen
          gleichermaßen für Personen männlichen und weiblichen Geschlechts) müssen
          lediglich die Kosten tragen, die mit der Teilnahme über das Internet
          verbunden sind (Nutzungsentgelt für Internetverbindung).
        </span>
      </p>
    </section>
    <h3 class="font-bold pb-4 pt-8">2. Teilnahme/Ablauf</h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">2.1</span>
        <span>
          Um an der Aktion teilzunehmen, muss der Teilnehmer sich im IQOS Club
          unter
          <a class="underline" href="https://www.iqos.com/"
            >https://www.iqos.com/</a
          >
          anmelden. Anschließend hat er die Möglichkeit einmal an der Aktion teilzunehmen.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">2.2</span>
        <span>
          Teilnahmeberechtigt sind natürliche Personen mit Wohnsitz in der Bundesrepublik
          Deutschland, die das 18. Lebensjahr vollendet haben und in der IQOS Datenbank
          registriert sind. Von der Teilnahme ausgeschlossen sind Mitarbeiter der Philip
          Morris GmbH und deren Angehörige sowie die Mitarbeiter verbundener Agenturen.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">2.3</span>
        <span>
          Es darf nur einmal pro Person teilgenommen werden. Eine mehrfache Teilnahme
          ist technisch nicht möglich und rechtlich nicht zulässig.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">2.4</span>
        <span>
          Eine Teilnahme über Gewinnspiel-Services, automatisierte Massenteilnahmeverfahren
          Dritter oder sonstige Manipulationen sind nicht gestattet.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">2.5</span>
        <span>
          Bei einem Verstoß gegen diese Teilnahmebedingungen behält sich der Veranstalter das
          Recht vor, den Teilnehmer vom Gewinnspiel auszuschließen. Gegebenenfalls können in
          diesen Fällen nachträglich Gewinne aberkannt und zurückgefordert werden.
        </span>
      </p>
    </section>
    <h3 class="font-bold pb-4 pt-8">
      3. Ermittlung der Gewinner und Abwicklung
    </h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">3.1</span>
        <span>
          Unter allen Teilnehmern in diesen Zeitraum wird 1 Gewinner in nichtöffentlicher
          Auslosung ermittelt. Die Teilnahme an der Verlosung erfolgt automatisch durch das
          Abschließen der „Umfrage Accessoires Customization“.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">3.2</span>
        <span>
          Die Gewinnbenachrichtigung erfolgt nach Aktionszeitraum per Email an die im
          Mitgliederkonto hinterlegte Email-Adresse nach Ende des Aktionszeitraums.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">3.3</span>
        <span>
          Verlost werden Gutscheine in verschiedenen Beträgen von BestChoice, Amazon,
          MediaMarkt und Kaufland.  Eine Auszahlung des Gewinns in bar, ein Tausch oder
          eine Übertragung des Gewinnes sind nicht möglich.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">3.4</span>
        <span>
          Außerdem erhält jeder Teilnehmer nach Abschließen der „Umfrage Accessoires
          Customization“ 50 Statuspunkte. (Statuspunkte definieren den Status im IQOS
          Club z.B. Gold – Mitglied und sind von Prämienpunkte, welche im Prämienshop
          eingelöst werden können, abzugrenzen.
        </span>
      </p>
    </section>
    <h3 class="font-bold pb-4 pt-8">
      4. Datenschutz/Einwilligung zur Nutzung personenbezogener Daten
    </h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">4.1</span>
        <span>
          Mit Teilnahme am Gewinnspiel erklärt sich der Teilnehmer damit einverstanden,
          dass Philip Morris und beauftragte Agenturen seine personenbezogenen Daten zur
          Durchführung des Gewinnspiels verarbeiten und verwenden.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">4.2</span>
        <span>
          Die Einwilligungserklärung kann jederzeit mit Wirkung für die Zukunft
          gegenüber Philip Morris GmbH, IQOS CLUB, Am Haag 14, 82166 Gräfelfing schriftlich
          oder per E-Mail an kontakt@IQOS.de widerrufen werden. Besondere Gebühren (über
          die regelmäßigen Übermittlungsgebühren hinaus) fallen hierbei nicht an. Eine
          (weitere) Teilnahme am Gewinnspiel ist bei Nichterteilung oder Widerruf meiner
          Einwilligung nicht (mehr) möglich.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">4.3</span>
        <span>
          Eine Weitergabe der Daten des Teilnehmers erfolgt im Falle eines Sachgewinns an das
          mit der Lieferung beauftragte Versandunternehmen, soweit dies zur
          Auslieferung/Zustellung dieses Gewinns an den Teilnehmer notwendig ist. Eine
          Übermittlung der personenbezogenen Daten an sonstige Dritte oder eine Nutzung der
          Daten zu sonstigen Werbezwecken ohne die Einwilligung des Teilnehmers findet nicht
          statt, es sei denn, dass der Veranstalter gesetzlich zur Herausgabe von Daten
          verpflichtet sind oder dass der Veranstalter zur Durchsetzung unserer Forderungen
          zur Berufsverschwiegenheit verpflichtete Dritte einschalten.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">4.4</span>
        <span>
          Weitere Einzelheiten bezüglich der Verarbeitung und Nutzung der personenbezogenen
          Daten bei der Teilnahme an den Gewinnspielen des Veranstalters erfährt der Teilnehmer
          in unserer
          <a
            class="underline"
            href="https://iqosplus.de/dse_full.pdf"
            >Datenschutzerklärung.</a
          >
        </span>
      </p>
    </section>
    <h3 class="font-bold pb-4 pt-8">5. Preise</h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">5.1</span>
        <span>
          Alle Sachpreise aus Verlosungen werden vom Veranstalter oder einem vom Veranstalter
          beauftragten Dritten per Spedition, Paketdienst oder Post an die im Mitgliedskonto
          hinterlegte Adresse versendet. Die Lieferung erfolgt innerhalb der Bundesrepublik
          Deutschland frei Haus. Darüber hinaus anfallende Transportkosten und Zölle hat der
          Gewinner zu tragen.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">5.2</span>
        <span>
          Die im Rahmen des Gewinnspiels als Preis präsentierten Gegenstände sind nicht
          zwingend mit dem gewonnenen Gegenstand identisch. Vielmehr können Abweichungen
          hinsichtlich Modell, Farbe o.Ä. bestehen. IQOS kann einen dem als Preis präsentierten
          Gegenstand gleichwertigen Gegenstand mittlerer Art und Güte auswählen.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">5.3</span>
        <span>
          Eine Barauszahlung der Gewinne oder eines etwaigen Gewinnersatzes ist nicht möglich.
          Der Gewinnanspruch ist nicht übertragbar.
        </span>
      </p>
    </section>
    <h3 class="font-bold pb-4 pt-8">6. Haftungsbeschränkung</h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">6.1</span>
        <span>
          Alle Angaben seitens des Veranstalters, insbesondere im Zusammenhang mit der
          Veröffentlichung von Gewinnernamen etc. erfolgen ohne Gewähr.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">6.2</span>
        <span>
          Soweit vom Gewinner Ansprüche im Zusammenhang mit den erhaltenen Gewinnen geltend
          gemacht werden, sind diese, soweit rechtlich zulässig, unmittelbar gegen den
          Hersteller/Händler, Lieferanten, Reiseveranstalter oder sonstigen Leistungsträger
          zu richten. Sollten diesbezügliche Ansprüche bei dem Veranstalter entstehen,
          werden diese an die jeweiligen Gewinner abgetreten.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">6.3</span>
        <span>
          Der Veranstalter haftet auf Schadenersatz - gleich aus welchem Rechtsgrund -
          nur bei Vorsatz und grober Fahrlässigkeit des Veranstalters, ihren gesetzlichen
          Vertretern oder Erfüllungsgehilfen sowie bei schuldhafter Verletzung wesentlicher
          Vertragspflichten. Wesentliche Vertragspflichten sind solche, die die Durchführung
          dieses Gewinnspiels überhaupt erst ermöglichen und auf die der Teilnehmer vertrauen
          durfte und vertraut hat. Bei nur leicht fahrlässiger Verletzung wesentlicher
          Vertragspflichten ist die Haftung begrenzt auf den Ersatz des vertragstypischen,
          zum Zeitpunkt der Nutzung der Aktionsseite vorhersehbaren Schadens. Diese
          Haftungsbeschränkung gilt nicht für von Philip Morris, ihren gesetzlichen
          Vertretern oder ihren Erfüllungsgehilfen schuldhaft verursachten Schäden aus
          der Verletzung des Lebens, des Körpers oder der Gesundheit sowie für die
          Haftung nach dem Produkthaftungsgesetz.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">6.4</span>
        <span>
          Voranstehende Haftungsbeschränkung gilt insbesondere für Schäden, durch Fehler,
          Verzögerungen oder Unterbrechungen in der Übermittlung von Daten o. ä., bei
          Störungen der technischen Anlagen oder des Services, unrichtige Inhalte,
          Verlust oder Löschung von Daten, Viren.
        </span>
      </p>
    </section>
    <h3 class="font-bold pb-4 pt-8">7. Sonstiges</h3>
    <section class="pl-4">
      <p class="flex">
        <span class="pr-2">7.1</span>
        <span>Es ist das Recht der Bundesrepublik Deutschland anwendbar.</span>
      </p>
      <p class="flex">
        <span class="pr-2">7.2</span>
        <span>
          Sollten einzelne dieser Bestimmungen ungültig sein oder werden, bleibt die Gültigkeit
          der übrigen Teilnahmebedingungen hiervon unberührt.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">7.3</span>
        <span>
          Der Veranstalter behält sich das Recht vor, die Teilnahmebedingungen – im
          Rahmen des rechtlich Zulässigen - jederzeit zu ändern oder zu korrigieren.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">7.4</span>
        <span>
          Der Veranstalter behält sich vor, das Gewinnspiel bei Vorliegen eines
          wichtigen Grundes (z.B. Viren im Computersystem, Manipulation oder Fehler
          in der Anwendung) zu jedem Zeitpunkt ohne Vorankündigung abzubrechen.
        </span>
      </p>
      <p class="flex">
        <span class="pr-2">7.5</span>
        <span>Der Rechtsweg ist ausgeschlossen.</span>
      </p>
      <h3 class="font-bold pb-4 pt-8">
        Stand: 14.06.2022
      </h3>
    </section>
  </div>
</template>
